.footer-outer-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(0, 0, 0);
  padding: 50px;
  color: white;
  position: relative;
  bottom: 0;
}

.footer-shop {
  margin-top: 30px;
  font-weight: bold;
  cursor: pointer;
}

.footer-shop a {
  color: orange;
  text-decoration: none;
}
 
.footer-shop a:hover {
  color: white;
}