.nav-bar {
  background-color: black !important;
}

.logo-cont {
  color: white !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo-cont img {
  display: block;
  margin-right: 5px;
}

.logo-cont div {
  font-weight: bold;
}

.logo-cont div:hover {
  font-weight: bold;
  color: orange;
}

.nav-link {
  color: white !important;
  margin-left: 30px !important;
  font-weight: bold !important;
}

.nav-link:hover {
  color: orange !important;

}