.canvas-outer-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(29, 29, 29);
  padding: 30px 0 60px 0;
}

.canvas-cont {
  margin: 30px 0 0 0;
  color: white;
  text-align: center;
}

.input-cont {
  color: rgb(224, 224, 224);
  background-color: black;
  padding: 20px 90px;
  border-radius: 5px;
}

.input-cont #inputdate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-cont button {
  border-radius: 5px;
}

.input-cont input {
  margin: 10px 0 15px 0;;
}

.warning-text {
  font-size: 14px;
}


@media (max-width: 600px) { 
  .warning-text {
    font-size: 12px;
  }
  .input-cont {
    padding: 15px 70px;
  }

  .canvas-outer-cont {
    padding: 0 0 60px 0;
  }
  
}


@media (max-width: 450px) { 
  .warning-text {
    font-size: 11px;
  }
  .input-cont {
    padding: 10px 50px;
    margin-top: 20px;
  }
}